import {createApp, h} from "vue";
import {createInertiaApp, Head} from "@inertiajs/inertia-vue3";
import {InertiaProgress} from "@inertiajs/progress";
import VCalendar from "v-calendar";
import route from "ziggy-js";
import VueGoogleMaps from "@fawmi/vue-google-maps";
import {resolvePageComponent} from "laravel-vite-plugin/inertia-helpers";
import mitt from "mitt";
import "../css/app.css";
import axios from "axios";
import Bugsnag from '@bugsnag/js'
import BugsnagPluginVue from '@bugsnag/plugin-vue'
import VueScrollTo from 'vue-scrollto';

window.axios = axios;
window.emitter = mitt();


InertiaProgress.init();

let bugsnagVue = null;

if (import.meta.env.VITE_BUGSNAG_API_KEY) {
    Bugsnag.start({
        apiKey: import.meta.env.VITE_BUGSNAG_API_KEY,
        plugins: [new BugsnagPluginVue()],
        releaseStage: import.meta.env.VITE_APP_ENV // This line is new
    })

    bugsnagVue = Bugsnag.getPlugin('vue')
}


createInertiaApp({
    resolve: (name) =>
        resolvePageComponent(
            `./Pages/${name}.vue`,
            import.meta.glob("./Pages/**/*.vue")
        ),
    setup({el, App, props, plugin}) {
        const app = createApp({render: () => h(App, props)})
            .use(plugin)
            .use(VueGoogleMaps, {
                load: {
                    key: import.meta.env.VITE_GOOGLE_API_KEY,
                    libraries: "places",
                },
            })
            .use(bugsnagVue)
            .mixin({methods: {route}})
            .use(VCalendar, {})
            .use(VueScrollTo);

            app.component('Head', Head);

            app.mount(el);
    },
});
